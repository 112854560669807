import 'styles/global.css'
import 'styles/global.less';
import 'styles/buttons.less';

import { setUtmParamCookies } from 'helpers/UtmParams';

export const onInitialClientRender = () => {
    setUtmParamCookies();
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'page_view', { page_path: location.pathname });
};
